export const FieldRequired = "Value is required.";
export const StripeSetupError = "Failed to initialize payment engine.";
export const StripeSetupIntentError = "Failed to process payment details. Please try another payment method.";
export const FiletaxCreateProfileError = "Cannot create payment profile. Please try again later.";
export const FiletaxPaymentError = "Payment failed. Please try again later.";
export const GeneralServerError =
    "Oops! Something went wrong. Please check all the information and try again or contact our support.";
export const AlternateNameRequired = "Alternate name is required.";
export const AnswerRequired = "Please select either 'Yes' or 'No' before proceeding.";
export const usZipCodeLength = "US Zip Codes must be 5 numbers.";
export const ZipCodeInvalid = "The Zip/Postal Code you entered is invalid.";
export const FileSizeError = "The file is too large. The file size must not exceed 4 MB.";
export const FileFormatError = "Only these extensions are accepted: .jpg .jpeg .png .pdf.";
export const dobInvalid = "You must be at least 18 to file a BOI Report with our service.";
export const ExpDateError = "Expiration date must be a future date.";
export const ExpDateInvalid = "Expiration date you entered is invalid.";
export const isPastDateError = "The date cannot be in the future.";
export const taxIdInvalid = "The Tax ID you entered is invalid.";
export const FileIsMissing = "File is missing. Please upload the file again.";
export const BusinessNameInvalid = "Company name can contain only letters, numbers, spaces, &, \u2019, -, and .";

export function nameInvalid(firstName: boolean) {
    return (firstName ? "First " : "Last ") + "Name must contain only letters, spaces, hyphens and apostrophes.";
}

export function dobExceedError(years: number) {
    return `Date of birth cannot exceed ${years} years.`;
}

export function FilNotFound(fileName: string) {
    return `${fileName} not found. You must add ${fileName} to continue.`;
}

export function FileNameError(maxLength: number) {
    return `File name cannot exceed ${maxLength} characters, including the file extension.`;
}

export function businessNameMaxLength(maxLength: number) {
    return `Business Name must be ${maxLength} characters or less.`;
}

export function alternameNameMaxLength(maxLength: number) {
    return `Alternate Names must be ${maxLength} characters or less.`;
}

export function taxIdFixedLength(maxLength: number) {
    return `A US EIN or SSN/ITIN must be ${maxLength} numbers.`;
}

export function foreignTaxIdMinLength(minLength: number) {
    return `A Foreign Tax ID must be at least ${minLength} alphanumeric characters.`;
}

export function foreignTaxIdMaxLength(maxLength: number) {
    return `A Foreign Tax ID must be ${maxLength} alphanumeric characters or less.`;
}

export function foreignTaxIdAllowedCharacters(allowedCharacters: string) {
    return `A Foreign Tax ID must be alphanumeric characters ${allowedCharacters}.`;
}

export function IdNumberAllowedCharacters(allowedCharacters: string) {
    return `The ID can only contain letters and numbers ${allowedCharacters}.`;
}

export function addressMaxLength(maxLength: number, hasAddressLine2: boolean) {
    return hasAddressLine2
        ? `Address 1 and Address 2 combined cannot exceed 100 characters.`
        : `The Address name must not exceed ${maxLength} characters.`;
}

export function cityMaxLength(maxLength: number) {
    return `The City name must not exceed ${maxLength} characters.`;
}

export function zipCodeMaxLength(maxLength: number, isUs: boolean) {
    return isUs
        ? `US Zip Codes must be ${maxLength} numbers.`
        : `Foreign Postal Codes must be between 1 and 9 characters.`;
}

export function foreignZipCodeLength() {
    return `Foreign Postal Codes must be between 1 and 9 characters.`;
}

export function nameMinLength(minLength: number, name: string) {
    return `${name} must exceed ${minLength} characters.`;
}

export function nameMaxLength(maxLength: number, name: string) {
    return `${name} must not exceed ${maxLength} characters.`;
}

export function miMaxLength(maxLength: number) {
    return `Only ${maxLength} character is accepted.`;
}

export function fileNameMaxLength(maxLength: number) {
    return `File name cannot exceed ${maxLength} characters, including the file extension.`;
}

export function idNumberMaxLength(maxLength: number) {
    return ` The ID number must be ${maxLength} characters or less.`;
}
